<div class="map-wrapper">
  <div>
    <app-map [areas]="mapAreas" [locations]="mapLocations"></app-map>
  </div>
</div>

<div class="article-container mat-app-background">

  <article>

    <div fxLayout="column wrap" fxLayoutAlign="center center">
      <header fxFlex>
        <h1>{{ locationInfo.title }}</h1>
      </header>

      <div class="gallery-container" fxFlex>
        <gallery
          gallerize
          [id]="'LOC_GALLERY'"
          [thumb]="false"
          [loadingStrategy]="'lazy'"
          [slidingDisabled]="true"
          [imageSize]="'cover'">
        </gallery>
      </div>

      <div fxFlex class="divider-container">
        <mat-divider></mat-divider>
      </div>

      <div class="description" fxFlex>
        <h2>Briefly about {{locationInfo.location.name}}</h2>
        <p>{{ locationInfo.description }}</p>
      </div>

      <ng-container *ngFor="let infoSection of locationInfo.sections;index as order">

        <div fxFlex class="divider-container">
          <mat-divider></mat-divider>
        </div>

        <section fxFlex>
          <app-info-section [order]="order" [locInfoSection]="infoSection"></app-info-section>
        </section>

      </ng-container>

    </div>

  </article>

</div>

