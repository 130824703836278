import {Component, OnInit} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterLink, RouterModule,
  RouterOutlet
} from "@angular/router";
import {filter, map} from "rxjs";
import {SeoService} from "./seo.service";
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {CommonModule} from '@angular/common';
import {MetaDataModel} from "./model/meta-data.model";
import {FlexModule} from "@angular/flex-layout";
import {FlexLayoutServerModule} from "@angular/flex-layout/server";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [MatProgressBarModule, MatToolbarModule, RouterLink, MatButtonModule, CommonModule, RouterOutlet, RouterModule, FlexModule,FlexLayoutServerModule]
})
export class AppComponent implements OnInit {
  loading: boolean = false;

  constructor(
    private router: Router,
    private seoService: SeoService) {
  }

  ngOnInit() {
    this.router.events.subscribe((e) => {
      this.navigationInterceptor(e);
    })
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data != null) {
            const data = route.snapshot.data;
            if (data['data'] != null && data['data']['meta'] != null) {
              return route!.snapshot.data['data']['meta'] as MetaDataModel;
            }
          }
          return null;
        })
      )
      .subscribe((meta: MetaDataModel) => {
        if (meta != null) {
          this.seoService.setMetadata(meta)
        }
      });
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event): void {
    if (event instanceof NavigationStart) {
      this.loading = true
    }
    if (event instanceof NavigationEnd) {
      this.loading = false
    }
    if (event instanceof NavigationCancel) {
      this.loading = false
    }
    if (event instanceof NavigationError) {
      this.loading = false
    }
  }
}
