import {afterNextRender, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Area, Location, LocationInfo} from "ng-geo-spatial-api";
import {ActivatedRoute, Router} from "@angular/router";
import {Gallery, GalleryComponent, ImageItem} from "ng-gallery";
import {LocationModel} from "../../model/location.model";
import {MatIcon} from '@angular/material/icon';
import {FlexLayoutModule} from "@angular/flex-layout";
import {FlexLayoutServerModule} from "@angular/flex-layout/server";
import {DataModel} from "../../model/data.model";
import {Subscription} from "rxjs";
import {MapComponent} from "../map/map.component";
import {MapLocation} from "../map/map-location.model";
import {AreaFlag, MapArea} from "../map/map-area.model";
import {MapService} from "../map/map.service";
import {MatDialog} from "@angular/material/dialog";
import {AsyncPipe, NgFor, NgIf} from "@angular/common";

import {environment} from "../../../environments/environment";
import {MatDivider} from "@angular/material/divider";
import {GallerizeDirective} from "ng-gallery/lightbox";
import {InfoSectionComponent} from "../shared/info-section/info-section.component";
import {LocPreviewDialogComponent} from "../shared/loc-preview-dialog/loc-preview-dialog.component";

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  standalone: true,
  imports: [MatIcon, GalleryComponent, FlexLayoutModule, FlexLayoutServerModule, MapComponent, NgFor, NgIf, MatDivider, GallerizeDirective, AsyncPipe, InfoSectionComponent],
})
export class LocationComponent implements OnInit, OnDestroy {
  mainArea: Area;
  outerArea: Area;
  otherAreas: Area[]
  locationInfo: LocationInfo;
  otherLocations: Location[];

  images: ImageItem[] = [];
  mapAreas: MapArea[] = [];
  mapLocations: MapLocation[] = []

  mapLocationFocusSubscription: Subscription;
  mapAreaFocusSubscription: Subscription;

  constructor(
    private gallery: Gallery,
    private route: ActivatedRoute,
    private router: Router,
    private mapService: MapService,
    private ngZone: NgZone,
    private dialog: MatDialog) {
    afterNextRender(() => {window.scrollTo(0, 0)})
    this.loadData();
  }


  loadData() {
    const data = this.route.snapshot.data['data'] as DataModel<LocationModel>;
    this.mainArea = data.model.mainArea;
    this.outerArea = data.model.outerArea;
    this.otherAreas = data.model.otherAreas;
    this.locationInfo = data.model.locationInfo;
    this.otherLocations = data.model.otherLocations;
    this.locationInfo.sections = this.locationInfo.sections.sort((a, b) => a.order - b.order);
    this.mapAreas = this.setMapAreas()
    this.mapLocations = this.setMapLocations()
    if (this.locationInfo.location.images != null) {
      const galleryRef = this.gallery.ref("LOC_GALLERY");
      const basePath = environment.API_BASE_PATH + "/api/image/download/";
      this.images = []
      galleryRef.reset()
      this.locationInfo.location.images.forEach(img => {
        galleryRef.add(new ImageItem(
          {src: basePath + img.large.name, thumb: basePath + img.small.name, alt: img.description}
        ))
      })
    }
  }

  ngOnInit(): void {
    if (this.mapLocationFocusSubscription != null && !this.mapLocationFocusSubscription.closed) {
      this.mapLocationFocusSubscription.unsubscribe();
    }
    this.mapLocationFocusSubscription = this.mapService.locationIdFocusEmitter.subscribe(locationId => {
      const location = this.otherLocations.find(l => l.id == locationId);
      if (location != null && location.route != null) {
        this.ngZone.run(() => {
            const dialogRef = this.openDialog(location)
            dialogRef.afterClosed().subscribe(route => {
              if (route != null) {
                this.router.navigate(['..', route], {relativeTo: this.route}
                ).then(r => {
                  this.loadData()
                })
              }
            });
          }
        )
      }
    });
    this.mapAreaFocusSubscription = this.mapService.areaIdFocusEmitter.subscribe(areaId => {
      const area = this.otherAreas.find(a => a.id == areaId)
      if (area != null && area.route != null)
        this.ngZone.run(() => this.router.navigate(["../..", area.route], {relativeTo: this.route})
          .then(r => this.loadData())
        );
    });
  }

  ngOnDestroy() {
    if (this.mapAreaFocusSubscription) {
      this.mapAreaFocusSubscription.unsubscribe()
    }
    if (this.mapLocationFocusSubscription) {
      this.mapLocationFocusSubscription.unsubscribe()
    }
  }

  setMapLocations() {
    const thisLocation = this.locationInfo.location as MapLocation
    thisLocation.labeled = true;
    const otherLocations = [...this.otherLocations as MapLocation[]]
    let index = otherLocations.findIndex((l) => l.id == thisLocation.id)
    if (index > -1) otherLocations.splice(index, 1)
    return [...otherLocations, thisLocation]

  }

  setMapAreas() {
    const mainArea = this.mainArea as MapArea
    mainArea.flags = [AreaFlag.CROP]

    const focusedArea = this.outerArea as MapArea;
    focusedArea.flags = [AreaFlag.FOCUS]

    const otherAreas = this.otherAreas as MapArea[]
    otherAreas.forEach(a => a.flags = [AreaFlag.FILL])

    let index = otherAreas.findIndex((a) => a.id == focusedArea.id)
    if (index > -1) otherAreas.splice(index, 1)

    return [mainArea, focusedArea, ...otherAreas]
  }


  openDialog(location: Location) {
    return this.dialog.open(LocPreviewDialogComponent, {data: location, panelClass: 'full-screen-modal'});
  }
}
