<div class="main-container">

  <div class="inner-container">

    <header>
      <h1 class="mat-headline-1">A <span>NEW</span> way to discover tourist places</h1>
    </header>

    <mat-divider></mat-divider>

    <section fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="1rem">

      <div fxFlex class="get-started-card mat-elevation-z4">
        <h2>
          We assist you in discovering potential vacation destinations or itineraries in <span style="color:#007ed6;">Romania</span>.
        </h2>
        <button mat-flat-button color="primary" routerLink="/locations/romania">Get started</button>
      </div>

    </section>

    <mat-divider></mat-divider>

    <section class="presentation">
      <h2>Our data is represented in a tree data structure with three levels</h2>
      <gallery
        [items]="images"
        [thumb]="false"
        [dots]="true"
        [counter]="false"
        [dotsSize]="10"
        [dotsPosition]="'top'"
        [slidingDuration]="1000">
        <ng-container *galleryImageDef="let item; let active = active">
          <div *ngIf="active" class="item-text" [innerHTML]="item?.alt">
          </div>
        </ng-container>
      </gallery>
    </section>

  </div>


</div>

