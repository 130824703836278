import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot, Routes} from '@angular/router';
import {
  AreaInfo,
  AreaInfoService,
  AreaService,
  Location,
  LocationInfo,
  LocationInfoService,
  LocationService,
  MultiresImage
} from "ng-geo-spatial-api";
import {AboutUsComponent} from "./components/about-us/about-us.component";
import {PrivacyTermsComponent} from "./components/privacy-terms/privacy-terms.component";
import {HomeComponent} from "./components/home/home.component";
import {environment} from "../environments/environment";
import {OuterAreaModel} from "./model/outer-area.model";
import {catchError, map, mergeMap, of,} from "rxjs";
import {InnerAreaModel} from "./model/inner-area.model";
import {LocationComponent} from "./components/location/location.component";
import {LocationModel} from "./model/location.model";
import {DataModel} from "./model/data.model";
import {Soft404Component} from "./components/soft-404/soft-404.component";

const homePageResolver: ResolveFn<DataModel<null>> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return {
    model: null,
    meta: setMetadata(
      "Discovereazy - Discover Tourist Attractions in Romania",
      "Welcome to Discovereazy! A travel guide where you can easily find popular and less crowded tourist attractions in Romania.",
      "home page keywords",
      "",
      null
    )
  }
}

const outerAreaWrapperResolver: ResolveFn<DataModel<OuterAreaModel>> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router)
  const areaService = inject(AreaService);
  return inject(AreaInfoService).getAreaInfoByRoute(route.params['area-route']).pipe(
    mergeMap(areaInfo => {
      return areaService.getAllAreas('REGION', areaInfo.area.id).pipe(
        map(innerAreas => {
          return {
            meta: setMetadataFromAreaInfo(areaInfo, state, null),
            model: {areaInfo: areaInfo, innerAreas: innerAreas}
          }
        })
      );
    }),
    catchError((error) => {
      console.log(error)
      router.navigate(['/error']);
      return of(null);
    })
  );
}

const innerAreaWrapperResolver: ResolveFn<DataModel<InnerAreaModel>> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router)
  const parentData = route.parent.data['data'] as DataModel<OuterAreaModel>;
  const locationService = inject(LocationService);
  return inject(AreaInfoService).getAreaInfoByRoute(route.params['area-route']).pipe(
    mergeMap(areaInfo => {
      return locationService.findAllLocations(areaInfo.area.id).pipe(
        map(locations => {
          return {
            meta: setMetadataFromAreaInfo(areaInfo, state, locations),
            model: {
              mainArea: parentData.model.areaInfo.area,
              areaInfo: areaInfo,
              locations: locations,
              otherAreas: parentData.model.innerAreas
            }
          }
        })
      )
    }),
    catchError((error) => {
      console.log(error)
      router.navigate(['/error']);
      return of(null);
    })
  );
}

const locationResolver: ResolveFn<DataModel<LocationModel>> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router)
  const parentData = route.parent.data['data'] as DataModel<InnerAreaModel>
  return inject(LocationInfoService).getLocationInfoByRoute(route.params['location-route']).pipe(
    map(locInfo => {
      return {
        meta: setMetadataFromLocationInfo(locInfo, state),
        model: {
          mainArea: parentData.model.mainArea,
          outerArea: parentData.model.areaInfo.area,
          otherAreas: parentData.model.otherAreas,
          locationInfo: locInfo,
          otherLocations: parentData.model.locations,
        }
      }
    }),
    catchError((error) => {
        console.log(error)
        router.navigate(['/error']);
        return of(null);
      }
    )
  )
}


function setMetadataFromAreaInfo(areaInfo: AreaInfo, state: RouterStateSnapshot, locations: Location[]) {
  let img: MultiresImage = null;
  if (locations != null && locations.length > 0 && locations[0].images != null && locations[0].images.length > 0) {
    img = locations[0].images[0]
  }
  return setMetadata(areaInfo.meta_title, areaInfo.meta_description, areaInfo.meta_keywords, state.url, img);
}

function setMetadataFromLocationInfo(locInfo: LocationInfo, state: RouterStateSnapshot) {
  let img: MultiresImage = null;
  if (locInfo.location.images != null && locInfo.location.images.length > 0) {
    img = locInfo.location.images[0]
  }
  return setMetadata(locInfo.meta_title, locInfo.meta_description, locInfo.meta_keywords, state.url, img);
}

function setMetadata(title: string, description: string, keywords: string, url: string, image: MultiresImage) {
  let img = environment.HOST + "/assets/images/logo-name-share-og.jpeg"
  if (image != null) {
    img = environment.HOST + environment.IMG_PATH + image.medium.name
  }
  return {
    title: title,
    description: description,
    keywords: keywords,
    url: environment.HOST + url,
    image: img
  };
}

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    resolve: {
      data: homePageResolver
    }
  },
  {path: 'about-us', component: AboutUsComponent, data: {title: "Discovereazy - About Us"}},
  {path: 'privacy-policy', component: PrivacyTermsComponent, data: {title: "Discovereazy - Privacy Policy"}},
  {path: "error", component: Soft404Component},
  {
    path: 'locations/:area-route',
    loadComponent: () => import('./components/outer-area-wrapper/outer-area-wrapper.component').then(mod => mod.OuterAreaWrapperComponent),
    resolve: {data: outerAreaWrapperResolver},
    runGuardsAndResolvers: "always",
    children: [
      {
        path: '',
        loadComponent: () => import('./components/outer-area-wrapper/outer-area/outer-area.component').then(mod => mod.OuterAreaComponent),
      },
      {
        path: ':area-route',
        loadComponent: () => import('./components/inner-area-wrapper/inner-area-wrapper.component').then(mod => mod.InnerAreaWrapperComponent),
        resolve: {data: innerAreaWrapperResolver},
        runGuardsAndResolvers: "always",
        children: [
          {
            path: '',
            loadComponent: () => import('./components/inner-area-wrapper/inner-area/inner-area.component').then(mod => mod.InnerAreaComponent),
          },
          {
            path: ':location-route',
            component: LocationComponent,
            resolve: {data: locationResolver},
          }
        ]
      },
    ]
  },
  {path: '**', redirectTo: "/error"}
]
