<div class="mat-app-background" fxLayout="column" fxFlexFill>
  <div class="navbar" style="z-index: 2" fxFlex="none">
    <div *ngIf="loading" class="loading-spinner">
      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    </div>
    <mat-toolbar>
      <mat-toolbar-row>
        <a style="height: 100%" routerLink="/">
          <img src="assets/images/logo-name.svg" width="auto" height="100%" alt="Logo">
        </a>
        <span class="spacer"></span>
        <button mat-button routerLink="/about-us">About us</button>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <div fxFlex>
    <router-outlet></router-outlet>
  </div>


  <mat-toolbar fxFlex="none">
    <span class="spacer"></span>
    <button mat-button routerLink="/privacy-policy">Privacy policy</button>
  </mat-toolbar>

</div>
