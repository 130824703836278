import { Component } from '@angular/core';

@Component({
    selector: 'app-privacy-terms',
    templateUrl: './privacy-terms.component.html',
    styleUrls: ['./privacy-terms.component.css'],
    standalone: true
})
export class PrivacyTermsComponent {

}
