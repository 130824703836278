import {Injectable} from "@angular/core";
import {Meta, Title} from "@angular/platform-browser";
import {MetaDataModel} from "./model/meta-data.model";

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private title: Title, private meta: Meta) {
  }

  updateCustomMeta(key: string, value: string) {
    this.meta.updateTag({name: key, content: value})
  }

  updateTitle(title: string) {
    this.title.setTitle(title);
    this.updateCustomMeta("og:title", title);
  }
  updateType(title: string) {
    this.updateCustomMeta("og:type", title);
  }

  updateUrl(url: string) {
    this.meta.updateTag({name: 'og:url', content: url})
  }

  updateDescription(desc: string) {
    this.meta.updateTag({name: 'description', content: desc})
    this.meta.updateTag({name: 'og:description', content: desc})
  }

  updateKeywords(keywords: string) {
    this.meta.updateTag({name: 'keywords', content: keywords})
  }
  updateImage(url: string) {
    this.meta.updateTag({name: 'og:image', content: url})
  }
  setMetadata(meta:MetaDataModel){
    this.updateUrl(meta.url)
    this.updateImage(meta.image)
    this.updateType("website")
    this.updateTitle(meta.title)
    this.updateKeywords(meta.keywords)
    this.updateDescription(meta.description)
  }

}
