<div class="header">
  <h1 class="mat-headline-2">About <span>Us</span></h1>
</div>

<div class="content">

  <section>
    <h2>Our Mission</h2>
    <p>At Discovereazy, our mission is to simplify your travel experience by providing fast and reliable information
      about tourist locations and parking places near them. We understand the importance of convenience when exploring
      new destinations, and we're here to make your journey smoother than ever.</p>
  </section>

  <section>
    <h2>What We Offer</h2>
    <p>Our platform is designed to help you quickly discover tourist attractions and parking facilities. Whether
      you're a local looking for a hidden gem or a traveler in search of a hassle-free parking spot, Discovereazy has
      got you covered. We strive to provide accurate and up-to-date information to enhance your travel planning.</p>
  </section>

  <section>
    <h2>Why Choose Discovereazy?</h2>
    <p>1. <strong>Speed:</strong> Our platform is built for speed, ensuring you get the information you need swiftly.</p>
    <p>2. <strong>Accuracy:</strong> We take pride in delivering accurate details about tourist locations and parking availability.</p>
    <p>3. <strong>Simplicity:</strong> Navigating through our website is simple and user-friendly, making your travel planning a breeze.</p>
  </section>

  <section>
    <p>Contact us at: <a href="mailto:cmatisevschii@gmail.com">cmatisevschii&#64;gmail.com</a></p>
  </section>

</div>


