import {Component, Inject, inject, Optional, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from "@angular/common";
import {Response} from 'express';
import {RESPONSE} from "../../model/server.token";
import {Platform} from "@angular/cdk/platform";
import {FlexModule} from "@angular/flex-layout";

@Component({
  selector: 'app-soft-404',
  standalone: true,
  imports: [
    FlexModule
  ],
  templateUrl: './soft-404.component.html',
  styleUrl: './soft-404.component.scss'
})
export class Soft404Component {

  constructor(
    @Optional() @Inject(RESPONSE) private response: Response,
    @Inject(PLATFORM_ID) private platformId: Platform
  ) {
    if (isPlatformServer(this.platformId)) {
      this.response?.status(404);
    }
  }
}
