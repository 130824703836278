import {Component, OnInit} from '@angular/core';
import {MatCard, MatCardImage} from '@angular/material/card';
import {RouterLink} from '@angular/router';
import {MatButton} from '@angular/material/button';
import {MatDivider} from '@angular/material/divider';
import {GalleryComponent, GalleryImageDef, ImageItem} from "ng-gallery";
import {NgIf} from "@angular/common";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FlexLayoutServerModule} from "@angular/flex-layout/server";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [MatDivider, FlexLayoutModule,FlexLayoutServerModule, MatButton, RouterLink, MatCard, MatCardImage, GalleryComponent, NgIf, GalleryImageDef]
})
export class HomeComponent implements OnInit {
  images: ImageItem[] = []

  constructor() {
  }

  ngOnInit(): void {
    this.images.push(
      new ImageItem({
        src: "/assets/images/country-level.svg",
        alt: "<h3>Country level</h3><p>Presents all available regions from inside the country</p>"
      }),
      new ImageItem({
        src: "/assets/images/region-level.svg",
        alt: "<h3>Region level</h3><p>Shows all tourist places from the selected region</p>"
      }),
      new ImageItem({
        src: "/assets/images/location-level.svg",
        alt: "<h3>Location level</h3><p>Gives brief information about the selected location</p>"
      })
    )
  }
}
