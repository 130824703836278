import {ApplicationConfig, importProvidersFrom} from "@angular/core";
import {Meta, provideClientHydration} from "@angular/platform-browser";
import {routes} from "./app.routes";
import {AdsenseModule} from "ng2-adsense";
import {environment} from "../environments/environment";
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";
import {ApiModule, BASE_PATH} from "ng-geo-spatial-api";
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideHttpClient, withFetch, withInterceptorsFromDi} from "@angular/common/http";
import {provideRouter, RouterModule,} from "@angular/router";


export const appConfig: ApplicationConfig = {
  providers:
    [{provide: BASE_PATH, useValue: environment.API_BASE_PATH},
      Meta,
      provideRouter(routes),
      provideClientHydration(),
      provideAnimations(),
      provideHttpClient(withFetch()),
      importProvidersFrom(
        RouterModule,
        AdsenseModule.forRoot({adClient: environment.ADSENSE_ID}),
        NgxGoogleAnalyticsModule.forRoot(environment.ANALYTICS_MEASUREMENT_ID),
        NgxGoogleAnalyticsRouterModule,
        ApiModule,
      ),
    ]
};
